import React from "react"
import { Helmet } from "react-helmet"

import "../styles/bootstrap.css"
import "../styles/all.css"
import "../styles/agency.css"

const IndexPage = () => (
  <>
    <Helmet>
      <script src="/vendor/jquery/jquery.min.js" />
      <script src="/vendor/bootstrap/js/bootstrap.bundle.min.js" />
      <script src="/vendor/jquery-easing/jquery.easing.min.js" />
      <script src="/js/agency.js" />
    </Helmet>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand js-scroll-trigger" href="#page-top">Public Health Research Collaborative</a>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          Menu
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav text-uppercase ml-auto">
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#about">Introduction</a>
            </li>
            <li class="nav-item">
                <a class="nav-link js-scroll-trigger" href="#recentpub">Latest publications</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#publication">Publications</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#contact">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="/">中</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  
    <header class="masthead">
      <div class="container">
        <div class="intro-text">
          <a class="btn btn-primary text-uppercase js-scroll-trigger" href="#recentpub">Latest publications</a>
        </div>
      </div>
    </header>
  
    <section class="bg-light page-section" id="recentpub">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h3 class="section-heading text-capitalize">Latest publications (only available in Chinese)</h3>
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-md-4 col-sm-6 portfolio-item">
              <a class="portfolio-link" data-toggle="modal" href="#recentPubModal1">
                <div class="portfolio-hover">
                </div>
                <img class="img-fluid" src="/img/air-quality-map.jpg" alt="" />
              </a>
              <div class="portfolio-caption">
                <h4>牛津流行病學家陳嘉鴻博士的呼籲：請戴口罩</h4>
                <p class="text-muted">2019 年 10 月 4 日</p>
              </div>
            </div>
  
            <div class="col-md-4 col-sm-6 portfolio-item">
              <a class="portfolio-link" data-toggle="modal" href="#recentPubModal2">
                <div class="portfolio-hover">
                </div>
                <img class="img-fluid" src="/img/tg-cleanup.png" alt="" />
              </a>
              <div class="portfolio-caption">
                <h4>關於清理催淚氣體殘留物的資料</h4>
                <p class="text-muted">2019 年 8 月 18 日</p>
              </div>
            </div>
  
            <div class="col-md-4 col-sm-6 portfolio-item">
              <a class="portfolio-link" data-toggle="modal" href="#recentPubModal3">
                <div class="portfolio-hover">
                </div>
                <img class="img-fluid" src="/img/cig3.jpg" alt="" />
              </a>
              <div class="portfolio-caption">
                <h4>政府應調整控煙政策︰訂立禁傳統煙時間表、規管另類煙草產品</h4>
                <p class="text-muted">2019 年 4 月 22 日</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  
    <section class="page-section" id="publication">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="section-heading text-capitalize">Publications</h3>
          </div>
        </div>
        <div class="row text-center justify-content-md-center">
          <div class="col-md-4">
            <img class="img-fluid" src="/img/Medical_Cover-preview-07-sm.jpg" alt="Book Cover" />
            <h4 class="service-heading">“The Unheard Voices in Hong Kong’s Crumbling Healthcare System”</h4>
            <p class="text-muted">By giving a voice to the people, doctors, nurses, allied health workers, hospital managers and policymakers, the book illustrates the plights and reasons behind our crippling public healthcare system and explore the possibility to put it back on track.</p>
          </div>
        </div>
      </div>
    </section>
  
    <section class="page-section" id="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="section-heading text-uppercase">Introduction</h3>
            <h3 class="section-subheading text-muted">Public Health Research Collaborative is a research organization founded in 2019 by members from the civil society. We aim to explore the healthcare system and public health development in Hong Kong through evidence-based research and investigation to promote local health literacy and participate in health-related policy advocacy.</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ul class="timeline">
              <li>
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" src="/img/begin.jpg" alt="" />
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>Early 2019</h4>
                    <h4 class="subheading">Establishment</h4>
                  </div>
                  <div class="timeline-body">
                    <p class="text-muted"></p>
                  </div>
                </div>
              </li>
  
              <li class="timeline-inverted">
                <div class="timeline-image">
                  <img class="rounded-circle img-fluid" src="/img/Medical_Cover-preview-07-ting-sq.jpg" alt="" />
                </div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4>July 2019</h4>
                    <h4 class="subheading">Published “The Unheard Voices in Hong Kong’s Crumbling Healthcare System”</h4>
                  </div>
                </div>
              </li>
                
            </ul>
          </div>
        </div>
      </div>
    </section>
  
    <section class="page-section" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="section-heading text-uppercase">Contact Us</h3>
            <h3 class="section-subheading text-light">Email: contact@hkphrc.com</h3>
          </div>
        </div>
      </div>
    </section>
  
    <footer class="footer" id="footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-4">
            <span class="copyright">Copyright &copy; Public Health Research Collaborative 2020</span>
          </div>
          <div class="col-md-4">
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <a href="https://medium.com/@hkphrc" target="_blank">
                  <i class="fab fa-medium"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://facebook.com/hkphrc" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  
    <div class="portfolio-modal modal fade" id="recentPubModal1" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="close-modal" data-dismiss="modal">
              <div class="lr">
                <div class="rl"></div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <div class="modal-body">
                    <h3 class="text-uppercase">牛津流行病學家陳嘉鴻博士的呼籲：請戴口罩</h3>
                    <p class="item-intro text-muted">2019 年 10 月 4 日</p>
                    <p>根據世界衞生組織數據，室外空氣污染每年造成超過四百萬人早逝，對心血管、呼吸道、糖尿病等疾病有重大影響。空氣中的懸浮粒子（PM2.5）帶有過百種有害化學物，吸入肺部後不但會破壞呼吸系統、降低人體免疫力，更會進入心血管系統，誘發心臟病、中風等嚴重疾病。</p>
                    <a href="https://www.facebook.com/hkphrc/photos/a.2324726097816083/2382867848668574/" target="_blank"><button class="btn btn-primary" type="button">
                    Read full article</button></a>
                    <button class="btn btn-secondary" data-dismiss="modal" type="button">
                      <i class="fas fa-times"></i>
                      Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    <div class="portfolio-modal modal fade" id="recentPubModal2" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="close-modal" data-dismiss="modal">
              <div class="lr">
                <div class="rl"></div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <div class="modal-body">
                    <h3 class="text-uppercase">關於清理催淚氣體殘留物的資料</h3>
                    <p class="item-intro text-muted">2019 年 8 月 18 日</p>
                    <p>施放催淚氣體後，空氣中的煙霧微粒和催淚物質有機會擴散至附近住宅、商戶及其他室內空間。催淚氣體會沿著地面聚集在通氣不良，低窪或狹窄的地區，市民應把受污染的室內空間在居住或逗留前進行徹底的清潔 [1]。 學術界對如何處理室內催類氣體污染未有明確指引。筆者搜集不同的文獻，嘗試整理有關清除催類氣體殘留物的資訊供大眾參考。</p>
                    <a href="https://medium.com/@hkphrc/關於清理催淚氣體殘留物的資料-information-on-handling-indoor-environment-tear-gas-contamination-5031d6842211" target="_blank"><button class="btn btn-primary" type="button">
                    Read full article</button></a>
                    <button class="btn btn-secondary" data-dismiss="modal" type="button">
                      <i class="fas fa-times"></i>
                      Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    <div class="portfolio-modal modal fade" id="recentPubModal3" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="close-modal" data-dismiss="modal">
              <div class="lr">
                <div class="rl"></div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <div class="modal-body">
                    <h3 class="text-uppercase">政府應調整控煙政策︰訂立禁傳統煙時間表、規管另類煙草產品</h3>
                    <p class="item-intro text-muted">2019 年 4 月 22 日</p>
                    <p>政府最近向立法會提交《2019年吸煙（公眾衞生）（修訂）條例草案》，希望全面禁止另類吸煙產品，例如電子煙、加熱非燃燒煙草製品及草本煙等的製造、進口、分銷、售賣和展示，引來不少迴響。政府應把握是次機會，全面檢討現時的控煙政策，調整對傳統煙草及另類吸煙產品的規管。</p>
                    <p>我們認為政府可一方面為全禁傳統煙草訂立時間表，另一方面在科學界仍未能確切指出電子煙如何影響人體以及其對非吸煙者的影響前，應先規管另類吸煙產品，並在將來有足夠科學證據的情況下，再決定是否調整相關政策。</p>
                    <a href="https://medium.com/@hkphrc/%E6%94%BF%E5%BA%9C%E6%87%89%E8%AA%BF%E6%95%B4%E6%8E%A7%E7%85%99%E6%94%BF%E7%AD%96-%E8%A8%82%E7%AB%8B%E7%A6%81%E5%82%B3%E7%B5%B1%E7%85%99%E6%99%82%E9%96%93%E8%A1%A8-%E8%A6%8F%E7%AE%A1%E5%8F%A6%E9%A1%9E%E7%85%99%E8%8D%89%E7%94%A2%E5%93%81-95b77566efef" target="_blank"><button class="btn btn-primary" type="button">
                    Read full article</button></a>
                    <button class="btn btn-secondary" data-dismiss="modal" type="button">
                      <i class="fas fa-times"></i>
                      Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
)

export default IndexPage
